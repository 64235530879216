@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
}
html {
	scroll-behavior: smooth;
}
:root {
	--background-color: #000000;
	--blue-2: #3f3e41;
	--blue-3: #40c1e0;
}
body {
	font-family: "Source Sans Pro", sans-serif;
	background-color: #000000;
	background-color: var(--background-color);
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-track {
	border-radius: 6rem;
	background: #3f3e41;
	background: var(--blue-2);
}
::-webkit-scrollbar-thumb {
	border-radius: 6rem;
	background: #40c1e0;
	background: var(--blue-3);
}
a {
	text-decoration: none;
}
img {
	width: 100%;
}
.container {
	max-width: 1080px;
	margin: auto;
}
.container-small {
	max-width: 800px;
	margin: auto;
}
.mb {
	margin-bottom: 1rem;
}
.border-btm {
	border-bottom: 1px solid #20a0f6;
}

/* *********************** */
/* ******* GLOBAL SECTION STYLESHEET***** */
/* *********************** */
header {
	position: absolute;
	z-index: 100;
	width: 100%;
	top: 0;
	left: 0;
	padding: 1rem 1.4rem;
}
.hero-section {
	position: relative;
}

.back-img {
	width: 100%;
	/* background: url(../img/rockintuna-header-bg-transparent.png) no-repeat center; */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	/* padding-top: 50%; */
}
.header-navigation {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.logo img {
	width: 100px;
}
.logo-mobile {
	width: 40%;
	margin: auto;
	display: none;
}
.logo-mobile img {
	width: 100px;
}
.navigation {
	display: flex;
	margin-left: 80px;
}
.navigation li {
	margin: 0rem 1rem;
}
.navigation li a {
	color: #f9fafc;
	font-size: 1rem;
}
.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}
.social-icons img {
	width: 35px;
	margin-right: 15px;
}
.wallet-btn {
	border: 1px solid #e1f7f5;
	color: #f9fafc;
	padding: 0.4rem 1.2rem;
	border-radius: 30px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
}
.wallet-btn-mobile {
	color: #003e57 !important;
	padding: 0.5rem 1.2rem;
	border-radius: 30px;
	background-color: #00fcff;
	font-weight: bold;
	text-align: center;
	display: none;
	cursor: pointer;
}
.bg-header-mob {
	display: none;
}
.hamburger {
	display: none;
	cursor: pointer;
}
.hamburger div {
	height: 4px;
	width: 30px;
	background-color: white;
	margin: 4px 0;
	transition: all 0.3s ease;
}

.hero-container {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.hero-content-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	max-width: 1080px;
	grid-gap: 5%;
	gap: 5%;
	margin: auto;
}
.hero-content {
	flex-basis: 34%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	padding-top: 60px;
}
.hero-content p {
	text-align: center;
	color: #f6f8f5;
}
.hero-logo img {
	max-width: 300px;
}
.hero-image {
	max-width: 500px;
}
.hero-buttons {
	margin-top: 1rem;
	text-align: center;
}
.hero-buttons a,
.roadmap-button a {
	background-color: #00fcff;
	display: inline-block;
	width: 175px;
	padding: 0.7rem 0.5rem;
	color: #003e57;
	font-weight: 700;
	font-size: 1rem;
	font-family: "Source Sans Pro";
	border-radius: 30px;
	cursor: pointer;
	margin-right: 0.3rem;
	text-align: center;
}

/* *********************** */
/* ******* ABOUT SECTION STYLESHEET***** */
/* *********************** */
.about-content-wrapper,
.launch-content-wrapper {
	max-width: 768px;
	margin: auto;
	padding: 3rem 0rem;
}
.about-content-wrapper h1,
.launch-content-wrapper h1 {
	color: #40c1e0;
	text-align: center;
	font-weight: 300;
	font-size: 3.2rem;
	margin-bottom: 1rem;
}
.about-content-wrapper p {
	color: #fefefe;
	text-align: center;
	font-size: 1.1rem;
	margin-bottom: 2.5rem;
}

/* *********************** */
/* ******* LAUNCH SECTION STYLESHEET***** */
/* *********************** */
.launch {
	text-align: center;
	color: #fefefe;
}
.lauch-information {
	display: flex;
	justify-content: center;
	grid-gap: 1rem;
	gap: 1rem;
}

.sales-info,
.key-info {
	flex-basis: 50%;
}
.sales-info h2,
.key-info h2 {
	font-weight: 500;
	color: #45c0d0;
}
.sales-info p,
.key-info p {
	color: #ddd;
	font-size: 1.1rem;
}
.sale,
.key-info {
	background-color: #1a4953;
	padding: 1.2rem 0rem;
	border-radius: 15px;
}
.key-info {
	display: flex;
	align-items: center;
	justify-content: center;
}
.key-info span {
	color: #fefefe;
	font-weight: bold;
}

.mint-rockin-tuna {
	margin: 1rem 0rem;
}
.mint-rockin-tuna h2 {
	margin-bottom: 0.5rem;
}
.mint-rockin-tuna p {
	font-size: 1.1rem;
	margin-bottom: 2rem;
}

.mint-container {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	padding-bottom: 50px;
}

.toggle {
	flex: 1 1;
	display: flex;
	height: 45px;
}

.mint-button {
	flex: 1 1;
	width: 100%;
	height: 45px;
	font-size: x-large;
	font-weight: bolder;
	border-radius: 15px;
	background-color: #20d44f;
	color: white;
}

.toggle-minus {
	flex: 1 1;
	font-size: x-large;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	background-color: #1a4953;
	color: white;
}

.toggle-amount {
	flex: 3 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: x-large;
	font-weight: bolder;
	background-color: #0a252b;
}

.toggle-plus {
	flex: 1 1;
	font-size: x-large;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	background-color: #1a4953;
	color: white;
}

.toggle-minus, .toggle-plus, .mint-button {
	border: none;
	outline: none;
	cursor: pointer;
}

/* *********************** */
/* ******* ROADMAP SECTION STYLESHEET***** */
/* *********************** */
.roadmap-detail h1,
.legend-content h1 {
	color: #40c1e0;
	font-weight: 300;
	font-size: 3.2rem;
	margin-bottom: 1rem;
}
.roadmap-content p,
.legend-content p {
	color: #fefefe;
	font-size: 1.1rem;
	margin-bottom: 1rem;
}
.roadmap-content-wrapper {
	display: flex;
	grid-gap: 5%;
	gap: 5%;
	padding: 3rem 0rem;
}
.roadmap-information h2 {
	font-weight: 500;
	color: #45c0d0;
}
.roadmap-information p {
	color: #fefefe;
	font-size: 1.1rem;
	margin-bottom: 0.8rem;
}
.roadmap-information .link {
	color: #fefefe;
	text-decoration: underline;
}
.roadmap-detail {
	flex-basis: 40%;
}
.roadmap-img {
	padding: 1.5rem 1.5rem 0rem 1.5rem;
}
.roadmap-button {
	margin: 1.5rem 0rem;
	text-align: justify;
}

/* *********************** */
/* ******* LEGENDS SECTION STYLESHEET***** */
/* *********************** */

.legend-content-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 5%;
	gap: 5%;
	padding: 5rem 0rem;
}
.legend-content {
	flex-basis: 40%;
}

/* *********************** */
/* ******* ARTIST SECTION STYLESHEET***** */
/* *********************** */
.artist {
	position: relative;
}
.separator {
	position: absolute;
	z-index: 1;
}
.artist-bg {
	position: relative;
}
.artist-container,
.team-container {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.flex {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.artist-content-wrapper {
	height: 100%;
	padding-top: 10rem;
}

.artist-content {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-basis: 50%;
}
.artist-content p {
	color: #fefefe;
	font-size: 1.1rem;
	margin-bottom: 0.8rem;
}
.artist-content span {
	font-size: 1.1rem;
	color: #45c0d0;
	letter-spacing: 7px;
}
.artist-content-img img {
	max-width: 250px;
	margin-top: 0.3rem;
}
.artist-img {
	max-width: 430px;
}
.artist-logos {
	display: flex;
}
.artist-logos img {
	max-width: 120px;
}

/* *********************** */
/* ******* TEAM SECTION STYLESHEET***** */
/* *********************** */

.team-content-wrapper {
	height: 100%;
	padding-top: 7rem;
}
.team-content h1 {
	text-align: center;
	font-weight: 300;
	font-size: 3.2rem;
	margin-bottom: 0.5rem;
	color: #fefefe;
}
.team-content p {
	color: #fefefe;
	font-size: 1.1rem;
	text-align: center;
	margin-bottom: 1rem;
}

.builders {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.builder {
	flex-direction: column;
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-basis: 24%;
}

.builder-info {
	color: white;
}
.builder-info h3 {
	margin-bottom: 0.2rem;
}

/* *********************** */
/* ******* Footer SECTION STYLESHEET***** */
/* *********************** */

footer {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 4rem 0rem;
}
.footer-logo img {
	max-width: 120px;
}
.footer-nav h3 {
	font-weight: bold;
	margin-bottom: 0.5rem;
	color: #40c1e0;
}
.footer-nav {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.footer-nav a {
	color: #fefefe;
	font-size: 1.1rem;
}
.footer-social-icons {
	display: flex;
	align-items: flex-start;
}
.footer-social-icons img {
	width: 40px;
	margin-right: 12px;
}
.copyright {
	color: #fefefe;
	text-align: center;
	padding: 0rem 0rem 2rem 0rem;
}

/* *****************************************  */
/***********    MEDIA QUERIES   ************  */
/* *****************************************  */

/* *********************** */
/* ******* Header ***** */
/* *********************** */
@media (max-width: 1050px) {
	.logo,
	.navigation {
		display: none;
	}
	.hamburger {
		display: block;
		position: relative;
		bottom: 5px;
	}
	.hero-logo {
		max-width: 220px;
	}
	.hero-image img {
		max-width: 370px;
	}
	.hero-content {
		flex-basis: 40%;
	}
	.container {
		margin: 0rem 1.5rem;
	}
}

@media (max-width: 768px) {
	.hero-logo {
		max-width: 180px;
	}
	.hero-image img {
		max-width: 290px;
	}
	.hero-content {
		flex-basis: 50%;
		padding-top: 0px;
	}
	.hero-buttons button {
		padding: 0.5rem 1rem;
		font-size: 0.7rem;
	}
	.wallet-btn {
		display: none;
	}
}

@media (max-width: 420px) {
	.close {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding-right: 1rem;
	}
	.bg-header-dsk {
		display: none;
	}
	.bg-header-mob {
		display: block;
	}
	.logo-mobile {
		display: block;
	}
	.navigation {
		display: block;
		transform: translate(-100%);
		position: fixed;
		top: 0;
		left: 0;
		margin-left: 0rem;
		padding-top: 1rem;
		width: 60%;
		height: 100vh;
		background-color: #000;
		transition: transform 0.5s ease-in;
	}
	.navigation li {
		margin: 1rem;
	}
	.wallet-btn-mobile {
		display: block;
	}
	.hero-content-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.hero-content {
		max-width: 70%;
		text-align: center;
	}
	.hero-image {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.hero-buttons a {
		margin-bottom: 0.5rem;
		width: 200px;
	}
	header {
		padding: 1rem;
	}
	.hero-container {
		padding-top: 5rem;
	}
}

@media (max-width: 380px) {
	.hero-image img {
		max-width: 200px;
	}
}
@media (max-width: 320px) {
	.hero-image {
		display: none;
	}
	.hero-container {
		padding-top: 0rem;
	}
}

/* *********************** */
/* ******* LAUNCH ***** */
/* *********************** */
@media (max-width: 420px) {
	.lauch-information {
		display: block;
	}
	.sales-info {
		margin-bottom: 1rem;
	}
	.mint-container {
		display: block;
	}
	.mint-button {
		margin-top: 15px;
	}
}

/* *********************** */
/* ******* ROADMAP ***** */
/* *********************** */
@media (max-width: 768px) {
	.roadmap-content-wrapper {
		display: block;
	}
	.roadmap-img {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1.5rem;
	}
	.roadmap-img img {
		max-width: 400px;
	}
	.roadmap-detail h1 {
		text-align: center;
	}
}
@media (max-width: 420px) {
	.roadmap-button {
		text-align: center;
	}
}

/* *********************** */
/* ******* LEGENDS ***** */
/* *********************** */
@media (max-width: 768px) {
	.legend-content-wrapper {
		display: block;
	}
	.legend-content {
		text-align: center;
	}
	.legend-image {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.legend-image img {
		margin-top: 2rem;
		max-width: 400px;
	}
}

/* *********************** */
/* ******* ARTIST & TEAM ***** */
/* *********************** */
@media (max-width: 1050px) {
	.artist-content-wrapper {
		height: 100%;
		padding-top: 6.5rem;
	}
	.artist-img {
		max-width: 350px;
	}
	.artist-logos img {
		max-width: 80px;
	}
	.team-content-wrapper {
		height: 100%;
		padding-top: 3.5rem;
	}
	.team-content h1 {
		font-size: 2.2rem;
	}
	.builder-img {
		max-width: 150px;
		max-height: 150px;
	}
	.social-icons img {
		margin-right: 8px;
	}
}

@media (max-width: 420px) {
	.separator {
		display: none;
	}
	.bg-header-dsk {
		display: none;
	}
	.bg-header-mob {
		display: block;
	}
	.flex {
		display: block;
	}
	.artist-logos,
	.artist-img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.artist-img,
	.artist-logos {
		padding: 2rem 0rem;
	}
	.artist-logos img {
		margin: 0rem 1rem;
	}
	.team-container {
		margin: 0rem 1.5rem;
	}
	.container-small {
		margin-top: 1rem;
	}
	.builders {
		flex-wrap: wrap;
		margin-top: 2.5rem;
	}
	.builder {
		flex-basis: 50%;
		margin-bottom: 1.5rem;
	}
	.legend-content h1,
	.roadmap-detail h1,
	.launch-content-wrapper h1,
	.about-content-wrapper h1 {
		font-size: 2.8rem;
	}
}
@media (max-width: 380px) {
	.artist-content-wrapper {
		height: 100%;
		padding-top: 4rem;
	}
	.artist-logos {
		padding: 1rem 0rem;
	}
	.builders {
		margin-top: 1.5rem;
	}
	.builder {
		flex-basis: 40%;
		margin-bottom: 1rem;
	}
	.team-content-wrapper {
		height: 100%;
		padding-top: 1.7rem;
	}
	.legend-content h1,
	.roadmap-detail h1,
	.launch-content-wrapper h1,
	.about-content-wrapper h1 {
		font-size: 2.4rem;
	}
}
@media (max-width: 320px) {
	.team-content-wrapper {
		height: 100%;
		padding-top: 1.7rem;
	}
	.artist-img,
	.artist-logos {
		padding: 0.5rem 0rem;
	}
	.artist-img img {
		max-width: 200px;
	}
	.builders {
		align-items: flex-start;
	}
	.builder {
		max-width: 100px;
	}
	.team-content h1 {
		font-size: 1.5rem;
	}
	.team-content p {
		font-size: 14px;
	}
	.builder p {
		font-size: 14px;
	}
	.legend-content h1,
	.roadmap-detail h1,
	.launch-content-wrapper h1,
	.about-content-wrapper h1 {
		font-size: 2.2rem;
	}
}

/* *********************** */
/* ******* Footer ***** */
/* *********************** */
@media (max-width: 420px) {
	footer {
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.footer-nav {
		margin-bottom: 2rem;
		align-items: center;
	}
	.footer-logo {
		margin-bottom: 3rem;
	}
}

.active {
	transform: translate(0%);
}

.close1 {
	transform: rotate(-45deg) translate(-4px, 6px);
	height: 2px !important;
	width: 25px !important;
}
.close2 {
	opacity: 0;
}
.close3 {
	transform: rotate(45deg) translate(-4px, -6px);
	height: 2px !important;
	width: 25px !important;
}

